import type { GCSFile } from '../types.ts'
import api from './api.ts'

export async function createFile(file: Partial<GCSFile>) {
  const url = '/v1/mdb/files/'
  const response = await api.post(url, file, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data as GCSFile
}

export async function deleteFile(fileId: string) {
  const url = `/v1/mdb/files/${fileId}`
  const response = await api.delete(url)
  return response.status
}
